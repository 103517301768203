import React from 'react'

export function Footer() {
   
    return (
        <section className="footer">
            <div>
                <h4>&#169; 2021 D & B Machine. All rights reserved.</h4>
            </div>
        </section>
        );
    }